import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min";
import $ from "jquery";

function getDataRealisation() {
  const realisations = {
    superdrob: {
      title: "SuperDrob",
      name: "SuperDrob",
      area: "572 mkw",
      location: "Warszawa, ul. Zajęcza 2B",
      time: "06.2019-08.2019",
      worksTitle: "Aranżacja wnętrza wg projektu Belotto Design, w tym:",
      works: [
        "Prace budowlano-wykończeniowe",
        "Kompletna instalacji wentylacji",
        "Instalacja CT/WL",
        "Instalacja wod-kan",
        "Instalacja elektrycznej",
        "Instalacja SSP",
        "Instalacja DSO",
        "Instalacja KD",
        "Instalacja tryskaczowa",
      ],
      bg: "url(assets/superdrob-header.jpg)",
      slides: 6,
      maxSlides: 6,
      folder: "superdrob",
    },
    orangetheory: {
      title: "Orangetheory Fitness",
      name: "Orangetheory Fitness",
      area: "320 mkw",
      location: "ul. Przemysława Gintrowskiego 53, Warszawa",
      time: "09.2018- 11.2018",
      worksTitle: "Wydzielenie akustyczne lokalu względem całego budynku",
      works: [
        "Roboty ogólnobudowlane (ściany działowe w technologi GK, sufity podwieszane, konstrukcje stalowe, wykończenia malarskie i glazurnicze, wykładziny LVT, aranżacja sali fitness)",
        "Instalacje HVAC",
        "Instalacje sanitarne",
        "Instalacje elektryczne",
        "Instalacje SSP i DSO",
        "Wykonanie logotypów i kasetonów reklamowych.",
      ],
      bg: "url(assets/orange.jpg)",
      slides: 7,
      maxSlides: 17,
      folder: "orangetheory",
    },
    zoetis: {
      title: "Aranżacja powierzchni – Zoetis Polska Sp. z o.o.",
      name: "Zoetis Polska Sp. z o.o.",
      area: "700M2",
      location: "Warszawa, ul. Postępu 17B,  Budynek Adgar Plaza One",
      time: "06.-07.2018",
      worksTitle: "",
      works: [
        "projekt wykonawczy",
        "demontaż elementów aranżacji i instalacji biura",
        "wykonanie nowego podziału powierzchni ścianami g-k oraz ścianami szklanymi",
        "regulacja podłóg podniesionych",
        "montaż wykładziny",
        "wykonanie napraw i uzupełnienia sufitu podwieszonego",
        "adaptację instalacji elektrycznej z wymianą oświetlenia",
        "adaptację instalacji HVAC",
        "remont kuchni",
        "dostawę mebli w zabudowie",
      ],
      bg: "url(assets/zoetis/slide1-full.jpg)",
      slides: 4,
      maxSlides: 4,
      folder: "zoetis",
    },
    walterherz: {
      title: "Walter Herz",
      name: "Walter Herz",
      area: "220 mkw",
      location: "Prosta Tower – Warszawa",
      time: "kwiecień 2017",
      worksTitle: "",
      works: [
        "Instalacje elektryczne i HVAC, demontaż i montaż ścian oraz wykładzin, postawienie ścianek szklanych, dostawa elementów dekoracyjnych",
      ],
      bg: "url(assets/walterherz/slide7-full.jpg)",
      slides: 7,
      maxSlides: 12,
      folder: "walterherz",
    },
    link4: {
      title: "Roboty adaptacyjne. Aranżacja: LINK4",
      name: "LINK4",
      area: "4600M2",
      location: "Warszawa, ul. Postępu, budynek “Adgar Plaza One",
      time: "kwiecień-lipiec 2018",
      worksTitle: "",
      works: [
        "Doprowadzenie powierzchni do shell&core",
        "Wykonanie nowego podziału powierzchni ścianami g/k oraz szklanymi",
        "Montaż wykładzin montaż sufitów akustycznych",
        "Wykonanie nowych instalacji: SSP, DSO, elektrycznych, IT, KD, HVAC",
        "Gruntowny remont łazienek",
        "Dostawa mebli  zabudowie",
        "Wykonanie dwóch dodatkowych centrali wraz z głównymi kanałami w szachcie",
        "Project Management",
      ],
      bg: "url(assets/link4/slide1-full.jpg)",
      slides: 7,
      maxSlides: 9,
      folder: "link4",
    },
    volvocar: {
      title: "Volvo Car Poland w Młochowie",
      name: "Volvo Polska",
      area: "900 mkw",
      location: "Warszawa, ul. Postępu, budynek “Adgar Plaza One",
      time: "9 tygodni",
      worksTitle: "",
      works: [
        "projekt wykonawczy",
        "całkowity demontaż elementów aranżacji o instalacji biura na II piętrze",
        "wykonanie nowego podziału powierzchni ścianami g-k oraz ścianami szklanymi",
        " montaż wykładziny",
        "sufit wraz z instalacjami malowany",
        "adaptacja instalacji elektrycznej z całkowitą wymianą oświetlenia",
        " wykonanie nowej instalacji HVAC",
        "gruntowny remont łazienek",
        "dostawa mebli w zabudowie",
      ],
      bg: "url(assets/volvocar/slide2-full.jpg)",
      slides: 6,
      maxSlides: 6,
      folder: "volvocar",
    },
    avalon: {
      title: "Fundacja AVALON",
      name: "Fundacja AVALON",
      area: "800 mkw",
      location: "",
      time: "3 miesiące",
      worksTitle: "",
      works: ["Aranżacja powierzchni", "project management"],
      bg: "url(assets/avalon/slide3-full.jpg)",
      slides: 5,
      maxSlides: 5,
      folder: "avalon",
    },
    greencafe_legionowo: {
      title: "Green Caffe Nero – Legionowo",
      name: "Green Caffe Nero",
      area: "240 mkw",
      location: "",
      time: "",
      worksTitle: "",
      works: ["Aranżacja powierzchni, project management"],
      bg: "url(assets/greencafe_legionowo/slide3-full.jpg)",
      slides: 7,
      maxSlides: 19,
      folder: "greencafe_legionowo",
    },
    intercars: {
      title: "Przebudowa budynku – Inter Cars S.A. w Czosnowie",
      name: "Inter Cars S.A.",
      area: "1600 mkw",
      location: "ul. Gdańska 15, Cząstków Polski",
      time: "4 miesiące",
      worksTitle: "",
      works: [
        "Roboty ogólnobudowlane",
        "Roboty konstrukcyjne",
        "Wymiana elewacji",
        "Instalacja wentylacji",
        "Instalacja klimatyzacji",
        "Instalacje wod-kan, Instalacja elektryczna",
        "Project Management",
      ],
      bg: "url(assets/intercars/slide4-full.jpg)",
      slides: 8,
      maxSlides: 13,
      folder: "intercars",
    },
    dahliamatic: {
      title: "DahliaMatic",
      name: "DahliaMatic",
      area: "1800 mkw",
      location: "Warszawa Libra Business Centre",
      time: "październik-grudzień 2017",
      worksTitle: "",
      works: [
        "Project Management",
        "Dostawa i układanie wykładziny",
        "Instalacje elektryczne i HVAC",
        "adaptacja/remodeling powierzchni biura",
      ],
      bg: "url(assets/dahliamatic/slide6-full.jpg)",
      slides: 6,
      maxSlides: 6,
      folder: "dahliamatic",
    },
    kuchnia_play: {
      title: "Kuchnia Play",
      name: "Play",
      area: "45 mkw",
      location: "Warszawa, ul. Taśmowa",
      time: "7-12 grudnia 2017",
      worksTitle: "",
      works: [
        "Okładziny ścian ze spieków kwarcowych",
        "Dostawa i montaż mebli oraz oświetlenia",
        "Project Management",
        "Demontaż i montaż istniejących zabudów",
      ],
      bg: "url(assets/kuchnia_play/slide4-full.jpg)",
      slides: 6,
      maxSlides: 6,
      folder: "kuchnia_play",
    },
    greencafe_wroclaw_kosciuszki: {
      title: "Green Caffe Nero – Pl. Kościuszki Wrocław",
      name: "Green Caffe Negro – Plac Kościuszki Wrocław",
      area: "210 mkw",
      location: "",
      time: "",
      worksTitle: "",
      works: [
        "Instalacje elektryczne",
        "Instalacje sanitarne",
        "Project Management",
      ],
      bg: "url(assets/greencafe_wroclaw_kosciuszki/slide4-full.jpg)",
      slides: 6,
      maxSlides: 6,
      folder: "greencafe_wroclaw_kosciuszki",
    },
    greencafe_wroclaw_dominikanska: {
      title: "Green Caffe Nero – Galeria Dominikańska Wrocław",
      name: "Green Caffe Nero – Galeria Dominikańska Wrocław",
      area: "120 mkw",
      location: "",
      time: "",
      worksTitle: "",
      works: [
        "Instalacje elektryczne",
        "Instalacje sanitarne",
        "Project Management",
      ],
      bg: "url(assets/greencafe_wroclaw_dominikanska/slide4-full.jpg)",
      slides: 6,
      maxSlides: 6,
      folder: "greencafe_wroclaw_dominikanska",
    },
    techtronic_industries_eastern_europe: {
      title: "Techtronic Industries Eastern Europe",
      name: "Techtronic Industries Eastern Europe",
      area: "1000 mkw",
      location: "Warszawa",
      time: "czerwiec 2017",
      worksTitle: "",
      works: [],
      bg: "url(assets/techtronic_industries_eastern_europe/slide1-full.jpg)",
      slides: 6,
      maxSlides: 20,
      folder: "techtronic_industries_eastern_europe",
    },
    henkel: {
      title: "Henkel",
      name: "Henkel",
      area: "570 mkw",
      location: "Warszawa, ul. Postępu",
      time: "",
      worksTitle: "",
      works: ["Prace demontażowe oraz wykończeniowe"],
      bg: "url(assets/henkel/slide11-full.jpg)",
      slides: 7,
      maxSlides: 22,
      folder: "henkel",
    },
    warsztat_kulinarny: {
      title: "Warsztat Kulinarny",
      name: "Warsztat Kulinarny",
      area: "220 mkw",
      location: "Gdańsk, biurowiec Tryton",
      time: "sierpień/wrzesień 2016",
      worksTitle: "",
      works: [
        "Kompleksowe prace wykończeniowe oraz prace elektryczne",
        "Dostawa i montaż mebli",
      ],
      bg: "url(assets/warsztat_kulinarny/slide2-full.jpg)",
      slides: 7,
      maxSlides: 11,
      folder: "warsztat_kulinarny",
    },
    grupa_azoty: {
      title: "Grupa Azoty",
      name: "Grupa Azoty",
      area: "180m2",
      location: "Warszawa, Chłodna 51, p.31",
      time: "czerwiec/lipiec 2014",
      worksTitle: "",
      works: [],
      bg: "url(assets/grupa_azoty/slide1-full.jpg)",
      slides: 7,
      maxSlides: 65,
      folder: "grupa_azoty",
    },
    philips: {
      title: "PHILIPS",
      name: "PHILIPS",
      area: "",
      location: "Warszawa, Aleje Jerozolimskie",
      time: "Wrzesień 2015",
      worksTitle: "",
      works: ["Prace demontażowe i wykończeniowe"],
      bg: "url(assets/philips/slide2-full.jpg)",
      slides: 7,
      maxSlides: 25,
      folder: "philips",
    },
    msd: {
      title: "MSD",
      name: "MSD",
      area: "411 mkw",
      location: "Warszawa, Chłodna 51, p.31",
      time: "kwiecień/maj 2015",
      worksTitle: "",
      works: [
        "Kompleksowe prace wykończeniowe",
        "instalacyjne",
        "sanitarne",
        "Dostawa oraz montaż mebli",
      ],
      bg: "url(assets/msd/slide7-full.jpg)",
      slides: 7,
      maxSlides: 14,
      folder: "msd",
    },
    itc: {
      title: "ITC",
      name: "ITC",
      area: "",
      location: "",
      time: "",
      worksTitle: "",
      works: [],
      bg: "url(assets/itc/slide1-full.jpg)",
      slides: 7,
      maxSlides: 13,
      folder: "itc",
    },
    adgar: {
      title: "Adgar",
      name: "Adgar",
      area: "",
      location: "",
      time: "",
      worksTitle: "",
      works: [],
      bg: "url(assets/adgar/slide1-full.jpg)",
      slides: 7,
      maxSlides: 8,
      folder: "adgar",
    },
    viviamo: {
      title: "Viviamo",
      name: "Viviamo",
      area: "",
      location: "",
      time: "",
      worksTitle: "",
      works: [],
      bg: "url(assets/viviamo/slide1-full.jpg)",
      slides: 4,
      maxSlides: 4,
      folder: "viviamo",
    },
  };

  const hash = window.location.hash;
  const title = hash.substr(1);

  $("#title").html(realisations[title].title);
  $("#name").html(realisations[title].name);
  $("#area").html(realisations[title].area);
  $("#location").html(realisations[title].location);
  $("#time").html(realisations[title].time);
  $("#worksTitle").html(realisations[title].worksTitle);
  $("#header").css("background-image", realisations[title].bg);

  for (let element in realisations[title]) {
    const value = realisations[title][element];
    const key = realisations[title];

    if (!value && element !== "worksTitle") {
      const $element = $("#" + element);

      if ($element.length === 1) {
        $element.parents("div.hide-null").hide();
      }
    }
  }
  if (!realisations[title].worksTitle && !realisations[title].works.length) {
    const $element = $("#worksTitle").parents("div.hide-null").hide();
  }

  const carousel = $("#carousel");
  const works = $("#works");
  const slides = realisations[title].slides;
  const maxSlides = realisations[title].maxSlides;

  realisations[title].works.forEach((item) => {
    works.append(` <li class="list__item d-flex">
    <span class="h-100 d-block"><span class="d-inline-block circle"></span></span>
    <span>${item};</span>
  </li>`);
  });

  for (let i = 1; i <= maxSlides; i++) {
    carousel.append(`<a
    href="./assets/${realisations[title].folder}/slide${i}-full.jpg"
    data-light="image-${i}"
    class="h-100"
    data-lightbox="example-set"
    >
    <div class="slider-img" style='background-image: url("./assets/${realisations[title].folder}/slide${i}.jpg")'></div>
    
    </a>
  </div>`);
    if (realisations[title].folder === "link4" && i == 9) {
      const link = document.querySelector("[data-light='image-9']");
      const lightbox = document.querySelector(".lightbox");
      const lightboxOverlay = document.querySelector(".lightboxOverlay");

      link.addEventListener("click", function () {
        setTimeout(() => {
          lightbox.style.display = "none";
          lightboxOverlay.style.display = "none";
        }, 100);

        window.open("https://goo.gl/maps/PuMQSj9WpWQbLWXu8", "_blank");
      });
    }
  }
  {
    /* <img class="w-100" loading="lazy" src="./assets/${realisations[title].folder}/slide${i}.jpg" alt="${realisations[title].title}"
  /> */
  }
  $("#carousel").slick({
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 700,
    slidesToShow: slides,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    variableWidth: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
}

export default getDataRealisation;

{
  /* <div>
<a
  href="./assets/superdrob/slide1-full.jpg"
  data-lightbox="image-1"
  ><img src="./assets/superdrob/slide1.jpg" alt=""
/></a>
</div> */
}
