import $ from "jquery";

export default () => {
  const btnMenu = $("#menuBtn");
  const nav = $(".main-nav-mobile");

  if (!nav.hasClass("main-nav-mobile-active")) {
    nav.addClass("d-none");
    nav.removeClass("d-block");
  } else {
  }
  btnMenu.on("click", function () {
    if (!nav.hasClass("main-nav-mobile-active")) {
      nav.removeClass("d-none");
      nav.addClass("d-block");
    }

    setTimeout(() => {
      nav.toggleClass("main-nav-mobile-active");
      $(this).toggleClass("show");
    }, 50);

    setTimeout(() => {
      if (!nav.hasClass("main-nav-mobile-active")) {
        nav.addClass("d-none");
        nav.removeClass("d-block");
      }
    }, 250);
  });
};
