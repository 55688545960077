function styleCards() {
  const cards = document.querySelectorAll(".page-servieces article");
  const mobileHam = document.querySelector(".mobile-ham");
  const compStyles = getComputedStyle(mobileHam);
  let mobileDevice =
    compStyles.getPropertyValue("display") === "block" ? true : false;

  window.addEventListener("resize", () => {
    mobileDevice =
      compStyles.getPropertyValue("display") === "block" ? true : false;

    calcMargin();
  });
  calcMargin();
  function removeStyle(element) {
    element.style = "";
  }
  function getElementHeight(element) {
    return element.offsetHeight;
  }
  function calcMargin() {
    if (mobileDevice) {
      cards.forEach((card) => {
        const img = card.querySelector(".section-1__col-img");
        const text = card.querySelector(
          ".section-1__col-text .section-1__card-big"
        );
        const imgHeight = getElementHeight(img);
        const textHeight = getElementHeight(text);
        const imgHeightHalf = imgHeight / 2;
        const margin = textHeight - imgHeightHalf + 53;

        card.setAttribute("style", `margin-bottom:${margin}px`);
      });
    } else {
      cards.forEach((card) => removeStyle(card));
    }
  }
}
export default styleCards;
