import "../sass/main.scss";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min";
import $ from "jquery";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import AOS from "aos/dist/aos";
import "slick-carousel/slick/slick";
import "bootstrap/dist/js/bootstrap.min.js";
import accordion from "./components/accordion";
import validationForm from "./components/validation";
import ValidationForm from "./components/validation";
import mobileMenu from "./components/mobileMenu";
import styleCards from "./components/styleCards";
import "lightbox2/dist/css/lightbox.css";
import lightbox from "lightbox2/dist/js/lightbox";
import getDataRealisation from "./components/realisations";

$(document).ready(function () {
  mobileMenu();
  styleCards();

  const slides = $("#carousel").children().length;

  $("#referencesSlider").slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    // fade: true,
    cssEase: "linear",
  });
  $("#next").on("click", function () {
    $("#referencesSlider").slick("slickNext");
  });
  $("#prev").on("click", function () {
    $("#referencesSlider").slick("slickPrev");
  });
  $(".collapse").collapse();

  const btns = document.querySelectorAll(".btn-hide");
  const btnsLink = document.querySelectorAll(".btn-link");

  btns.forEach((btn) => {
    btn.addEventListener("click", function () {
      $(btn.closest(".collapse")).collapse("hide");
    });
  });
  btnsLink.forEach((btn) => {
    btn.addEventListener("click", function () {
      $(btn.closest(".card").querySelector(".collapse")).collapse("show");
    });
  });

  $(document).scroll(function () {
    var $nav = $(".navbar-fixed-top");
    $nav.toggleClass("scrolled", $(this).scrollTop() > $nav.height());
  });
  AOS.init();

  const elem = window.location.hash;

  if (elem == "#contact") {
    setTimeout(function () {
      scrolltoSection("#sectionContact");
    }, 800);
  } else if (elem == "#servieces") {
    setTimeout(function () {
      scrolltoSection("#serviecesSection");
    }, 800);
  } else if (elem == "#realisations") {
    setTimeout(function () {
      scrolltoSection("#realisationsSection");
    }, 800);
  } else if (elem == "#job") {
    setTimeout(function () {
      scrolltoSection("#jobSectionForm");
    }, 800);
  }

  $("a[href='#contact']").click(function () {
    if ($("body").hasClass("page-home")) {
      scrolltoSection("#sectionContact");
    }
  });

  $(".contact-btn").click(function (e) {
    e.preventDefault();
    window.location.href = "index.html#contact";
  });
  $(".servieces-btn").click(function (e) {
    e.preventDefault();
    window.location.href = "servieces.html#servieces";
  });
  $(".realisations-btn").click(function (e) {
    e.preventDefault();
    window.location.href = "realisations.html#realisations";
  });

  $(".section-5__card-button").click(function (e) {
    e.preventDefault();
    window.location.href = "jobs.html#job";
  });

  function scrolltoSection(elem) {
    $("html, body").animate(
      {
        scrollTop: $(elem).offset().top - 80,
      },
      800
    );
  }
  if (document.body.classList.contains("page-jobs")) {
    const validationForm = new ValidationForm(
      document.querySelector("#jobsForm")
    );
  } else if (document.body.classList.contains("page-home")) {
    const validationForm = new ValidationForm(
      document.querySelector("#contactForm")
    );
    const buttonFilm = document.querySelector(".header__aside-film");

    buttonFilm.addEventListener("click", function () {
      const modalContent = $("#modal").find(".modal-content");
      const iframe = $("<iframe>", {
        id: "modal-iframe",
        width: "560",
        height: "315",
        src: "https://www.youtube.com/embed/mYx7bVocRkE",
        frameborder: "0",
        allow:
          "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        allowfullscreen: "yes",
      });

      modalContent.append(iframe);

      iframe.on("load", function () {
        $("#modal").modal("show");
      });
    });
  }
  if (document.body.classList.contains("subpage-realisations")) {
    getDataRealisation();

    lightbox.option({
      alwaysShowNavOnTouchDevices: true,
      showImageNumberLabel: false,
    });
    console.log(lightbox);
  }

  $("#modal").on("hide.bs.modal", function (e) {
    $(this).find(".modal-content").html("");
  });

  $(".btn-confirm-more").on("click", function () {
    setTimeout(() => {
      console.log(this);
      const extra = $(this).prev();

      extra.toggleClass("d-inline");
      if (extra.parent(".error-field").hasClass("error")) {
        extra.parent(".error-field").removeClass("error");
      }
      if (extra.hasClass("d-inline")) {
        $(this).text(" [ukryj zgodę]");
      } else {
        $(this).text(" [pokaż zgodę]");
      }
    }, 0);
  });

  function handleClickCategories() {
    $(".nav-link__arrow").toggleClass("active");
    $(".submenu").slideToggle();
  }

  $(".nav-link__arrow").on("click", handleClickCategories);
});
